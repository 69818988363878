import * as React from 'react';
import { Container } from 'reactstrap';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

const ImpressumPage = () => (
  <Layout>
    <Helmet>
      <title>Datenschutz</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <TopNavBar />
    <Container className="fg-content-mt">
      <div style={{ paddingTop: '3.75rem', paddingBottom: '3.75rem' }}>
        <h1>Datenschutz</h1>
        <p className="mt-4">
          Wir freuen uns über Ihren Besuch auf den Webseite von FROGO AD. Der
          Schutz Ihrer personenbezogenen Daten ist uns sehr wichtig. Mit dieser
          Datenschutzerklärung möchten wir Sie über den Umgang mit Ihren
          personenbezogenen Daten bei Besuch unserer Webseiten und über Ihre
          Rechte informieren.
        </p>
        <h3 className="mt-4">Wer sind wir und wie können Sie uns erreichen?</h3>
        <p>
          Wir, die <br />
          <br />
          <strong>FROGO AD</strong> UG (haftungsbeschränkt) i. L.<br />
          c/o Freie Universität Berlin <br />
          Malteserstr. 74-100 <br />
          12249 Berlin <br />
          E-Mail: contact@frogo-ad.com <br />
          <br />
          sind als Verantwortliche Stelle dafür zuständig, Ihre
          personenbezogenen Daten zu schützen. Bei Fragen zur Datenverarbeitung,
          zu Ihren Rechten oder zur Datenschutzerklärung steht Ihnen auch unser
          Datenschutzbeauftragter gerne zur Seite. Diesen erreichen Sie unter:
          privacy@frogo-ad.com
        </p>
        <h3 className="mt-4">
          Welche Daten werden beim Besuch unserer Webseite verarbeitet?
        </h3>
        <p>
          Im Folgenden informieren wir Sie darüber, welche Daten bei Ihrem
          Besuch auf unseren Webseiten erhoben werden, zu welchen Zwecken diese
          verarbeitet werden, auf welcher Rechtsgrundlage die Datenverarbeitung
          erfolgt, welche Möglichkeiten Sie haben, um die Erhebung und
          Verarbeitung der Daten selbst zu steuern und wann die Daten gelöscht
          werden.
        </p>
        <h4 className="mt-4">A. Logfiles</h4>
        <h6>Erhobene Daten:</h6>
        <p>
          Beim Besuch unserer Webseiten werden von Ihrem Browser automatisch
          folgende Daten übermittelt:{' '}
        </p>
        <ul>
          <li>Ihre IP-Adresse</li>
          <li>Die Webseite, von der Sie kommen</li>
          <li>Webseiten, die Sie über unsere Seite aufrufen</li>
          <li>Die Seiten, die Sie anklicken, sowie</li>
          <li>Uhrzeit des Seitenaufrufs</li>
          <li>Name Ihres Internet Service Providers</li>
          <li>Ihr Browsertyp und dessen Version</li>
          <li>Das Betriebssystem Ihres Endgeräts</li>
          <li>Das Datum und die Dauer des Besuchs.</li>
        </ul>

        <h6>Zwecke der Datenverarbeitung:</h6>
        <p>
          Die vorübergehende Speicherung dieser Daten ist notwendig, um eine
          Auslieferung der Webseite an Ihren Rechner zu ermöglichen und um die
          Funktionsfähigkeit der Webseite sicherzustellen. Mit Hilfe dieser
          Daten gewinnen wir auch statistische Erkenntnisse darüber, wie unsere
          Webseiten genutzt werden. Darüber hinaus erfassen wir die Daten, um
          unzulässige Zugriffe auf den Webserver und die missbräuchliche Nutzung
          der Webseiten rückverfolgen und verhindern zu können und zur Sicherung
          unserer informationstechnischen Systeme.
        </p>
        <h6>Rechtsgrundlage:</h6>
        <p>
          Wir speichern diese Daten vorübergehend auf Grundlage berechtigter
          Interessen (Art. 6 Abs. 1 f DSGVO). Unser berechtigtes Interesse liegt
          darin, die vorstehend beschriebenen Zwecke zu erreichen.
        </p>
        <h4 className="mt-4">
          B. Allgemeine Informationen zu Cookies und Targeting-Technologien
        </h4>
        <h6>Erhobene Daten:</h6>
        <p>
          Beim Besuch unserer Webseiten werden sog. Cookies gesetzt. Hierbei
          handelt es sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt
          werden. Cookies enthalten im Regelfall eine charakteristische
          Zeichenabfolge, die sog. cookieID, mit der Ihr Browser bei einem
          erneuten Aufruf unserer Webseiten identifiziert werden kann.
        </p>
        <p>
          Darüber hinaus nutzen wir sog. Tags, das sind kleine Code-Elemente,
          mit deren Hilfe wir das Verhalten unserer Nutzer und den Erfolg von
          Werbeaktivitäten messen können.
        </p>
        <p>
          Je nach Art der Cookies bzw. Tags werden unterschiedliche Daten
          erhoben und pseudonymisiert verarbeitet.
        </p>
        <p>
          Wir setzen sowohl eigene Cookies, als auch Cookies von anderen
          Anbietern ein (Drittanbietercookies). Die Drittanbietercookies sind
          unten in Ziff. II. C im Einzelnen dargestellt.
        </p>
        <h6>Zwecke der Datenverarbeitung:</h6>
        <p>
          Technisch notwendige Cookies ermöglichen die technische Funktionsweise
          der Webseite. Einige Funktionen unserer Webseiten können ohne den
          Einsatz der Cookies nicht angeboten werden.
        </p>
        <p>
          Funktionalitätscookies dienen dazu, die Bedienbarkeit unserer
          Webseiten benutzerfreundlicher zu gestalten und bestimmte
          Funktionalitäten zu gewährleisten, z.B. die seitenübergreifende
          Warenkorbanzeige, in der Sie ablesen können, wie viele Artikel sich
          gerade in Ihrem Warenkorb befinden und die Speicherung Ihrer
          Anmeldedaten, damit Sie bei einem erneuten Seitenaufruf auf Ihre
          bereits eingegebenen Daten und Einstellungen zurückgreifen können.
        </p>
        <p>
          Analysecookies und Tags ermöglichen uns, Gesamtstatistiken zu
          generieren, z.B. über die Anzahl der Aufrufe, welche Bereiche der
          Webseiten am häufigsten angesehen werden und Angaben über Standorte
          und über die Dauer des durchschnittlichen Aufenthalts auf den
          Webseiten. Hierdurch können wir die Qualität unserer Webseiten und der
          Inhalte verbessern.
        </p>
        <p>
          Werbecookies und Retargeting-Technologien ermöglichen es uns, Ihnen
          gezielt auf Sie zugeschnittene Angebote und Informationen zur
          Verfügung zu stellen. Hierdurch können wir unsere Webseiten für Sie
          interessanter gestalten und Sie auf anderen Webseiten mit
          personalisierter, interessenbasierter Werbung ansprechen.
        </p>
        <h6>Zwecke der Datenverarbeitung:</h6>
        <p>
          Wir setzen technisch notwendige Cookies und Funktionalitätscookies auf
          Grundlage berechtigter Interessen ein (Art. 6 Abs. 1 f DSGVO). Unser
          berechtigtes Interesse liegt darin, das Funktionieren unserer
          Webseiten und deren optimale Bedienbarkeit zu gewährleisten.
        </p>
        <p>
          Wir setzen Analysecookies und Werbecookies sowie Tags und
          Retargeting-Technologien auf Grundlage berechtigter Interessen ein
          (Art. 6 Abs. 1 f DSGVO, Erwägungsgrund 47). Unser berechtigtes
          Interesse liegt darin, unsere Webseiten optimal auf die Interessen
          unserer Kunden zuzuschneiden.
        </p>
        <h6>Speicherdauer und Steuerungsoptionen:</h6>
        <p>
          Einige der von uns verwendeten Cookies werden nach dem Schließen des
          Browsers wieder automatisch gelöscht (sog. Session Cookies), andere
          verbleiben dauerhaft auf Ihrem Endgerät und ermöglichen uns, Ihren
          Browser wiederzuerkennen (sog. Persistente Cookies).
        </p>
        <p>
          Sie haben die volle Kontrolle über die Verwendung von Cookies und
          können in Ihrem Browser Cookies löschen, das Speichern von Cookies
          gänzlich deaktivieren oder bestimmte Cookies selektiv akzeptieren.
          Bitte verwenden Sie die Hilfefunktionen Ihres Browsers, um zu
          erfahren, wie Sie diese Einstellungen ändern können. Hierdurch kann
          die Funktionalität unserer Webseiten eingeschränkt sein.
        </p>
        <h4 className="mt-4">
          C. Eingesetzte Cookie- und Trackingtechnologien von Drittanbietern
        </h4>
        <h5>Google:</h5>
        <h6>Erhobene Daten:</h6>
        <p>
          <strong>Google Analytics:</strong> Auf unseren Webseiten nutzen wir
          Google Analytics, einen Webanalysedienst der Google Inc., Amphitheatre
          Parkway, Mountain View, CA 94043, USA („Google“). Google Analytics
          setzt Cookies auf Ihrem Endgerät, die es ermöglichen, Ihre Nutzung
          unserer Webseiten auszuwerten. Hierzu erhebt Google z.B. Daten, um
          Ihren Browser eindeutig zu identifizieren, Informationen darüber, wann
          und wie oft Sie unsere Webseiten aufgerufen haben, wie lange Sie sich
          auf unseren Webseiten aufgehalten haben und wie Sie mit unseren
          Webseiten interagiert haben (mehr Informationen dazu finden Sie{' '}
          <a href="https://www.google.de/policies/technologies/types/">hier</a>
          ). Wir haben Google Analytics um den Code{' '}
          <code>get._anonymizeIP();</code>, erweitert. Dieser führt dazu, dass
          Google Ihre IP-Adresse kürzt und eine anonymisierte Auswertung
          ermöglicht. Die Kürzung der IP-Adressen erfolgt innerhalb der EU oder
          des Europäischen Wirtschaftsraums. Nur in Ausnahmefällen wird die
          volle IP-Adresse an einen Server von Google in den USA übertragen und
          dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser
          übermittelte IP-Adresse wird nicht mit anderen Daten von Google
          zusammengeführt. Die mit Hilfe der Cookies ermittelten Daten werden in
          der Regel an einen Server von Google in den USA übertragen und dort
          gespeichert. Die Einhaltung der datenschutzrechtlichen Standards und
          Ihrer Rechte wird durch eine Zertifizierung von Google unter dem EU-US
          Privacy Shield sichergestellt. Google übermittelt Daten an Dritte,
          soweit eine Einwilligung hierzu erteilt wurde, dies aus rechtlichen
          Gründen erforderlich ist oder Dritte diese Daten im Auftrag von Google
          verarbeiten.
        </p>
        <p>
          <strong>Google Remarketing und Double Click:</strong> Wir nutzen
          Google Remarketing und Google Double Click. Bei dieser Technologie
          werden Cookies gesetzt, die auswerten, wie Sie unsere Webseite nutzen
          und die es ermöglichen, Ihren Browser wiederzuerkennen, wenn Sie
          Webseiten aufrufen, die zum Google Werbenetzwerk gehören. Der Google
          Analytics-Tracking-Code verwendet zu diesem Zweck neben den Google
          Analytics Cookies sog. DoubleClick-Cookies. Diese sammeln Daten dazu,
          welche Webseiten von Drittanbietern im Google Display Netzwerk Sie
          besucht haben und welche Werbung Sie angeklickt haben. Darüber hinaus
          werden Daten aus Erstanbieter-Cookies (z.B. Google-Analytics Cookies)
          und Drittanbieter-Cookies (z.B. Google Cookie für Anzeigevorgaben)
          verknüpft. Dies ermöglicht es uns, die Einblendung von Werbeanzeigen
          und Ihre Interaktion mit diesen Werbeanzeigen auszuwerten.
        </p>
        <p>
          <strong>Google AdWords Conversion Tracking:</strong> Wir nutzen Google
          AdWords Conversion Tracking. Bei dieser Technologie werden Cookies
          gesetzt, wenn Sie mit einer unserer Werbeanzeigen interagieren, z.B.
          darauf klicken. Mit Hilfe der Cookies wird analysiert, was passiert,
          nachdem Sie mit einer Werbeanzeige interagiert haben, z.B. ob Sie
          unser Produkt gekauft, von einem Mobiltelefon aus die Anzeige
          aufgerufen oder unsere App heruntergeladen haben oder sich für einen
          Newsletter angemeldet haben.
        </p>
        <p>
          <strong>Google Tag Manager:</strong> Der Google Tag Manager hilft uns
          beim Setzen und Verwalten von Tags. Ihre Daten werden durch den Dienst
          nicht erfasst.
        </p>
        <p>
          <strong>Google reCAPTCHA:</strong> Wir nutzen bei einigen Formularen
          den Dienst reCAPTCHA von Google. Hierbei erhebt Google bestimmte Daten
          um festzustellen, ob ein Mensch oder eine Maschine auf unsere
          Webseiten zugreift, z.B. Ihre IP-Adresse, Ihre Bildschirm- und
          Fensterauflösung, die in Ihrem Browser eingestellte Sprache, die
          Zeitzone, in der Sie sich befinden, der User-Agent des Browsers und
          installierte Browser Plug-Ins. Wir haben den Dienst um den Code
          "get._anonymizeIP();", erweitert. Dieser führt dazu, dass Google Ihre
          IP-Adresse kürzt. Weitere Informationen zur Kürzung der IP-Adresse
          können Sie oben in den Erklärungen zu Google Analytics nachlesen.
        </p>
        <h6>Zwecke der Datenverarbeitung:</h6>
        <p>
          <strong>Google Analytics:</strong> In unserem Auftrag verwendet Google
          die über Google Analytics erhobenen Daten, um die Nutzung unserer
          Webseiten auszuwerten, um Reports über die Webseitenaktivitäten
          zusammenzustellen und um weitere, mit der Webseitennutzung und der
          Internetnutzung verbundene Dienstleistungen zu erbringen.
        </p>
        <p>
          <strong>Google Remarketing und Double Click:</strong> Wir setzen diese
          Technologie ein, um Ihnen auf anderen Webseiten des
          Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu
          präsentieren. Die Werbeanzeigen beziehen sich auf Inhalte, die Sie
          sich vorher auf unseren Webseiten angeschaut haben.
        </p>
        <p>
          <strong>Google AdWords Conversion Tracking:</strong> Wir setzen diese
          Technologie ein, um unsere Angebote zu verbessern.
        </p>
        <p>
          <strong>Google Tag Manager:</strong> Wir setzen diesen Dienst ein, um
          Tags auf unserer Webseite zu erstellen, auszuspielen und zu verwalten.
        </p>
        <p>
          <strong>Google reCAPTCHA:</strong> Wir setzen diesen Dienst ein, um zu
          unterscheiden, ob eine Eingabe in einem unserer Webformulare durch
          einen Menschen oder missbräuchlich automatisiert/maschinell erfolgt
          und damit zum Schutz unserer technischen Systeme.
        </p>
        <p>
          <strong>YouTube:</strong> Wir nutzen YouTube, um Videos auf unseren
          Webseiten einbinden zu können.
        </p>
        <h6>Rechtsgrundlage:</h6>
        <p>
          Die beschriebenen Google Produkte setzen wir ein, wenn Sie darin
          eingewilligt haben. Wir holen Ihre Einwilligung beim Aufruf unserer
          Webseiten über das Cookiebanner am unteren Rand der Webseiten ein.
        </p>
        <p>
          Google reCAPTCHA setzen wir auf Grundlage berechtigter Interessen
          (Art. 6 Abs. 1 f DSGVO) ein. Unser berechtigtes Interesse liegt darin,
          die missbräuchliche Nutzung unserer Formulare zu verhindern und unsere
          technischen Systeme zu schützen.
        </p>
        <h6>Speicherdauer und Steuerungsoptionen:</h6>
        <p>
          Die Daten, die über die Google Funktionen erhoben werden, werden
          gespeichert und regelmäßig gelöscht. Sie können die Speicherung der
          Cookies durch eine entsprechende Einstellung in Ihrem Browser
          verhindern.
        </p>
        <p>
          Sie können die Erfassung Ihrer Daten durch Google Analytics
          verhindern, indem Sie auf folgenden Link klicken. Es wird ein
          Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
          Besuchen dieser Website verhindert:{' '}
          <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>
        </p>
        <p>
          Sie können darüber hinaus die Erhebung der Daten sowie die
          Verarbeitung der Daten durch Google verhindern, indem Sie das unter
          dem folgenden{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">Link</a> verfügbare
          Browser Add-On herunterladen und installieren.
        </p>
        <p>
          <strong>
            Google Dynamic Remarketing und Double Click sowie Google AdWords
            Conversion Tracking:
          </strong>{' '}
          Sie können der Speicherung der Cookies und der damit verbundenen
          Datenverarbeitung widersprechen, in dem Sie über Ihre{' '}
          <a href="https://www.google.com/ads/preferences/html/opt-out.html">
            Werbeeinstellungen
          </a>{' '}
          personalisierte Werbung deaktivieren. Die Verwendung von Cookies durch
          Drittanbieter können Sie über die{' '}
          <a href="http://optout.networkadvertising.org/#/">
            Deaktivierungswebseite
          </a>{' '}
          der Netzwerkwerbeinitiative deaktivieren. DoubleClick-Cookies können
          Sie alternativ durch die Installation eines{' '}
          <a href="https://www.google.com/settings/u/0/ads/plugin">
            Browser-Plug-Ins
          </a>{' '}
          deaktivieren.
        </p>
        <p>
          Hierdurch kann die Funktionalität unserer Webseiten eingeschränkt
          sein.
        </p>
        <p>
          Weitere Informationen finden Sie in der{' '}
          <a href="https://www.google.de/intl/de/policies/privacy/">
            Google Datenschutzerklärung
          </a>
          .
        </p>
        <h3 className="mt-4">
          Welche Daten werden verarbeitet, wenn Sie mit uns Kontakt aufnehmen,
          einen Newsletter bestellen, ein Nutzerkonto eröffnen oder
          Dienstleistungen in Anspruch nehmen?
        </h3>
        <p>
          Im Folgenden informieren wir Sie darüber, welche Daten erhoben und
          verarbeitet werden, wenn Sie mit uns Kontakt aufnehmen, einen
          Newsletter bestellen, einen Account eröffnen oder Dienstleistungen in
          Anspruch nehmen, zu welchen Zwecken und von welchen Empfängern diese
          verarbeitet werden, auf welcher Rechtsgrundlage die Datenverarbeitung
          erfolgt und wann die Daten gelöscht werden.
        </p>
        <h4 className="mt-4">A. Kontaktaufnahme</h4>
        <h6>Erhobene Daten:</h6>
        <p>
          Wir erheben und verarbeiten die von Ihnen mitgeteilten Daten, wie Ihre
          Kontaktdaten, Ihren Namen und ihr Anliegen, wenn Sie über ein
          Kontaktformular oder per E-Mail mit uns in Kontakt treten. Alle Daten,
          die Sie uns übermitteln, werden verschlüsselt zwischen Ihren Browser
          und unserem Server übertragen.
        </p>
        <h6>Zwecke der Datenverarbeitung:</h6>
        <p>
          Die Datenverarbeitung erfolgt durch unseren Kundenservice oder von uns
          beauftragten Dienstleistern ausschließlich auf Grundlage und zur
          Bearbeitung Ihrer Anfrage.
        </p>
        <h6>Rechtsgrundlage:</h6>
        <p>
          Wir verarbeiten Ihre Daten zur Durchführung vorvertraglicher und
          vertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen (Art. 6
          Abs. 1 b DSGVO).
        </p>
        <h6>Speicherdauer:</h6>
        <p>
          Wir speichern Ihre Daten so lange, wie wir sie für den konkreten
          Verarbeitungszweck, zur Gewährleistung oder zur Erfüllung gesetzlicher
          Aufbewahrungsfristen benötigen.
        </p>
        <h4 className="mt-4">B. Newsletter</h4>
        <h6>Erhobene Daten:</h6>
        <p>
          Wir erheben und verarbeiten die von Ihnen mitgeteilten Daten, wie
          Ihren Namen und Ihre E-Mail-Adresse, wenn Sie unseren Newsletter zum
          “Early Access” bestellen.
        </p>
        <h6>Zwecke der Datenverarbeitung:</h6>
        <p>
          Die Daten verwenden wir ausschließlich für den Versand der
          angeforderten Informationen und geben sie nicht an Dritte weiter. Die
          erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse
          sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
          widerrufen, etwa über den "Austragen"-Link im Newsletter.
        </p>
        <h6>Rechtsgrundlage:</h6>
        <p>
          Nach der Registrierung für einen Themen-Newsletter verarbeiten wir
          Ihre Daten mit Ihrer Einwilligung, um Sie per elektronischer Post über
          die ausgewählten Themen zu informieren und auch werblich anzusprechen.
          Wir arbeiten hierbei nach dem Double-Opt-In Prinzip, d.h. wenn Sie auf
          unserer Webseite einen Newsletter bestellen, lassen wir Ihnen eine
          Bestätigungs-E-Mail mit einem Anmeldelink zukommen. Erst wenn Sie auf
          diesen Link klicken, sind Sie auf unserer Newsletter-Liste.
        </p>
        <p>
          Sofern wir im Zusammenhang mit dem Verkauf eines Produkts Ihre
          E-Mail-Adresse erhalten haben, nutzen wir diese, um Ihnen auf
          Grundlage berechtigter Interessen (Art. 6 f DSGVO) Informationen zu
          ähnlichen Produkten zukommen zu lassen. Unser berechtigtes Interesse
          liegt darin, Sie direkt werblich anzusprechen.
        </p>
        <h6>Speicherdauer:</h6>
        <p>
          Wir speichern Ihre Daten so lange, wie wir sie für den konkreten
          Verarbeitungszweck benötigen.
        </p>
        <h3 className="mt-4">
          Welche Rechte haben Sie und wie können Sie diese wahrnehmen?
        </h3>
        <h4 className="mt-4">A. Widerruf der Einwilligung</h4>
        <p>
          Sie können ggf. erteilte Einwilligungen in die Verarbeitung Ihrer
          personenbezogenen Daten jederzeit mit Wirkung für die Zukunft
          widerrufen. Bitte beachten Sie, dass der Widerruf keine Auswirkungen
          auf die Rechtmäßigkeit der bisherigen Datenverarbeitung hat und dass
          er sich nicht auf solche Datenverarbeitungen erstreckt, für die ein
          gesetzlicher Erlaubnisgrund vorliegt und die daher auch ohne Ihre
          Einwilligung verarbeitet werden dürfen.
        </p>
        <h4 className="mt-4">B. Weitere Betroffenenrechte</h4>
        <p>
          Darüber hinaus stehen Ihnen nach den Artikeln 15 bis 21 und 77 der
          EU-Datenschutzgrundverordnung (DSGVO) bei Vorliegen der gesetzlichen
          Voraussetzungen folgende Betroffenenrechte zu:
        </p>
        <h6>Auskunft:</h6>
        <p>
          Sie können jederzeit verlangen, dass wir Ihnen Auskunft darüber
          erteilen, welche Ihrer personenbezogenen Daten wir wie verarbeiten und
          eine Kopie der von Ihnen gespeicherten personenbezogenen Daten zur
          Verfügung stellen, Art. 15 DSGVO.
        </p>
        <h6>Berichtigung:</h6>
        <p>
          Sie können die Berichtigung unrichtiger personenbezogener Daten sowie
          Vervollständigung unvollständiger personenbezogener Daten verlangen,
          Art. 16 DSGVO.
        </p>
        <h6>Löschung:</h6>
        <p>
          Zur Löschung Ihrer personenbezogenen Daten: Bitte beachten Sie, von
          der Löschung ausgenommen sind Daten, die wir zur Durchführung und
          Abwicklung von Verträgen und zur Geltendmachung, Ausübung und
          Verteidigung von Rechtsansprüchen benötigen sowie Daten, für die
          gesetzliche, aufsichtsrechtliche oder vertragliche
          Aufbewahrungspflichten bestehen, Art. 17 DSGVO.
        </p>
        <h6>Einschränkung der Verarbeitung:</h6>
        <p>
          Sie können unter bestimmten Umständen die Einschränkung der
          Verarbeitung verlangen, z.B. wenn Sie der Ansicht sind, dass Ihre
          Daten unrichtig sind, wenn die Verarbeitung rechtswidrig ist oder Sie
          Widerspruch gegen die Datenverarbeitung eingelegt haben. Dies führt
          dazu, dass Ihre Daten ohne Ihre Einwilligung nur sehr beschränkt
          verarbeitet werden dürfen, z.B. zur Geltendmachung, Ausübung und
          Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte anderer
          natürlicher und juristischer Personen, Art. 18 DSGVO.
        </p>
        <h6>Widerspruch gegen die Datenverarbeitung:</h6>
        <p>
          Sie haben die Möglichkeit, jederzeit der Datenverarbeitung zu
          Direktwerbezwecken zu widersprechen. Zudem können Sie bei Vorliegen
          besonderer Gründe jederzeit den Datenverarbeitungen widersprechen, die
          auf Grundlage eines berechtigten Interesses erfolgen, Art. 21 DSGVO.
        </p>
        <h6>Datenübertragbarkeit:</h6>
        <p>
          Sie haben das Recht, die Daten, die Sie uns bereitgestellt haben und
          die wir auf Grundlage Ihrer Einwilligung oder zur Vertragserfüllung
          verarbeiten, in einem gängigen, maschinenlesbaren Format zu erhalten
          und im Rahmen des technisch machbaren eine direkte Übermittlung dieser
          Daten an Dritte zu verlangen, Art. 20 DSGVO.
        </p>
        <h4 className="mt-4">C. Kontaktwege</h4>
        <p>
          Sie können Ihre Rechte über den folgenden <strong>Kontaktweg</strong>{' '}
          ausüben: <br />
          <br />
          <strong>FROGO AD</strong> UG (haftungsbeschränkt) i. L.<br />
          c/o Freie Universität Berlin <br />
          Malteserstr. 74-100 <br />
          12249 Berlin <br />
          E-Mail: privacy@frogo-ad.com <br />
          <br />
        </p>
        <p>
          Ihre Einwilligung in den Erhalt des Newsletters können Sie jederzeit
          auch durch Klicken des entsprechenden Links in jedem Newsletter
          widerrufen.
        </p>
        <h4 className="mt-4">
          D. Recht auf Beschwerde bei einer Aufsichtsbehörde
        </h4>
        <p>
          Wenn Sie z.B. der Ansicht sind, dass unsere Datenverarbeitung
          rechtswidrig ist oder wir die oben beschriebenen Rechte nicht in dem
          notwendigen Umfang gewährt haben, haben Sie das Recht, bei der
          zuständigen Datenschutzaufsichtsbehörde Beschwerde einzulegen.
        </p>
        <p className="mt-5 small">Stand: August 2018</p>
      </div>
    </Container>
    <Footer />
  </Layout>
);

export default ImpressumPage;
